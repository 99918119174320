/* eslint-disable max-lines */
import './GenericSchedule.scss';
import { defineComponent, ref } from 'vue';
import useLocation from '@/compositions/useLocation';
import { useActiveTeams, useWaitingTeams } from '@/compositions/useTeams';
import { midnightOfDate } from '@/classes/timestamps';
import { getScheduledRoomIdForTeam, setScheduledRoomIdForTeam } from '@/types/teams';
import useRooms from '@/compositions/useRooms';
import { useToast } from 'vue-toastification';
import { Selector } from '@/components/UI';
import { cloneDeep } from 'lodash';
import NewScheduleModel from '../NewScheduleModal';
import TeamSelector from '@/components/Teams/TeamSelector.vue';
export default defineComponent({
    name: "GenericSchedule",
    components: {
        Selector,
        NewScheduleModel,
        TeamSelector
    },
    props: {
        teams: {
            type: Object,
            required: true,
        },
        day: {
            type: Date,
            required: true,
        },
    },
    setup(props) {
        const localTeams = ref(cloneDeep(props.teams));
        return {
            localTeams: localTeams,
            ...useActiveTeams(),
            ...useWaitingTeams(),
            ...useRooms(),
            ...useLocation(),
            toast: useToast()
        };
    },
    data: () => ({
        showNewScheduleModal: false,
        editableTeams: [],
        editStartDate: null,
        fixSchedule: false,
        history: [],
        future: [],
        editMode: false,
        scheduleSettings: {
            minimize_chosen_rooms: true,
            handle_combined_rooms: true
        }
    }),
    computed: {
        teamIds() {
            return Object.keys(this.localTeams);
        },
        timeslots() {
            const slots = [];
            const startDate = midnightOfDate(this.day);
            const startTimestamp = startDate.getTime();
            for (let index = 0; index <= 24 * 3; index++) {
                const date = new Date(startTimestamp + index * 20 * 60 * 1000);
                slots.push(date);
            }
            return slots;
        },
        teamSlots() {
            const slots = Object.entries(this.localTeams).map(([key, team]) => {
                if (team.start_time === null) {
                    return [key, null];
                }
                return [key, {
                        start: new Date(team.start_time * 1000),
                        end: new Date((team.start_time + team.duration) * 1000)
                    }];
            });
            return Object.fromEntries(slots);
        },
        currentTimeSlotClass() {
            const currentTime = new Date(this.$store.direct.state.clock.now * 1000);
            const currentTimeSlot = Math.floor(currentTime.getMinutes() / 20);
            return this.timeslots.map(timeslot => {
                const compareTimeSlot = Math.floor(timeslot.getMinutes() / 20);
                return ((currentTime.getHours() === timeslot.getHours())
                    && (currentTimeSlot === compareTimeSlot)) ? "currentTimeSlot" : "";
            });
        }
    },
    watch: {
        teams: {
            handler: function (newTeams) {
                if (!this.editMode) {
                    this.localTeams = cloneDeep(newTeams);
                }
            },
            deep: true
        }
    },
    mounted() {
        // @ts-ignore
        this.$nextTick(() => {
            const position = window.document.querySelector("#currentTimeSlot");
            const scrollDistance = position.getBoundingClientRect().top - (window.innerHeight * 0.25);
            if (scrollDistance > 50) // The browser remembers the viewport, only scroll when needed
                window.scrollTo({ top: scrollDistance, behavior: 'smooth' });
        });
    },
    methods: {
        // eslint-disable-next-line max-lines-per-function
        getRoomOptions(teamId, timeslot) {
            const team = this.localTeams[teamId];
            if (team === undefined) {
                console.error('getRoomOptions: team not found');
                return [];
            }
            let roomOptions = [
                { value: 'reactionroom', text: 'ReactionRoom' },
                { value: 'lasermaze', text: 'LaserMaze' },
                { value: 'coderoom', text: 'CodeRoom' },
                { value: 'birdsroom', text: 'BirdsRoom' },
                { value: 'phoneroom', text: 'PhoneRoom' },
                { value: 'searchroom', text: 'SearchRoom' },
                { value: 'arcaderoom', text: 'ArcadeRoom' },
                { value: 'spiderroom', text: 'SpiderRoom' },
                { value: 'repeatroom', text: 'RepeatRoom' },
                { value: 'jungleroom', text: 'JungleRoom' },
                { value: 'bedroom', text: 'BedRoom' }
            ];
            roomOptions = roomOptions.filter(option => {
                var _a;
                if (getScheduledRoomIdForTeam(team, timeslot.getTime() / 1000) === option.value) {
                    return true;
                }
                for (const otherTeamId of this.teamIds.filter(id => id !== teamId)) {
                    const otherTeam = this.localTeams[otherTeamId];
                    if (otherTeam === undefined) {
                        console.error('getRoomOptions: other team not found');
                        continue;
                    }
                    if (getScheduledRoomIdForTeam(otherTeam, timeslot.getTime() / 1000) === option.value) {
                        return false;
                    }
                }
                return !((_a = this.localTeams[teamId]) === null || _a === void 0 ? void 0 : _a.schedule.includes(option.value));
            });
            return [{ value: null, text: '' }, ...roomOptions];
        },
        hasChanged(teamId, slot) {
            const history = this.history[this.history.length - 1];
            if (history === undefined) {
                return false;
            }
            const team = this.localTeams[teamId];
            if (team === undefined) {
                return false;
            }
            const lastTeam = history[teamId];
            if (lastTeam === undefined) {
                return false;
            }
            return getScheduledRoomIdForTeam(team, slot.getTime() / 1000) !==
                getScheduledRoomIdForTeam(lastTeam, slot.getTime() / 1000);
        },
        getRoom(teamId, timeslot) {
            const team = this.localTeams[teamId];
            if (team === undefined) {
                console.error('getRoom: team not found');
                return null;
            }
            return getScheduledRoomIdForTeam(team, timeslot.getTime() / 1000);
        },
        timeToString(time) {
            const hours = time.getHours();
            const minutes = time.getMinutes();
            if (minutes === 0) {
                return `${hours}:00`;
            }
            return `${hours}:${minutes}`;
        },
        setRoom(teamId, timeslot, roomId) {
            const team = this.localTeams[teamId];
            if (team === undefined) {
                console.error('setRoom: team not found');
                return;
            }
            if (roomId !== "" && this.rooms[roomId] === undefined) {
                return;
            }
            this.future = [];
            this.history.push(cloneDeep(this.localTeams));
            const timestamp = timeslot.getTime() / 1000;
            setScheduledRoomIdForTeam(team, timestamp, roomId);
        },
        startMultiSchedule(teams) {
            console.log('teams', teams);
            this.editableTeams = teams;
            this.editableTeams.forEach(team => {
                team.start_time = this.editStartDate.getTime() / 1000;
            });
            this.fixSchedule = true;
            this.showNewScheduleModal = true;
            this.editStartDate = null;
        },
        startNewSchedule(teamId, slot) {
            const team = this.localTeams[teamId];
            if (team === undefined) {
                console.error('addSchedule: team not found');
                return;
            }
            team.start_time = slot.getTime() / 1000;
            this.editableTeams = [this.teams[teamId]];
            this.fixSchedule = true;
            this.showNewScheduleModal = true;
        },
        removeSchedule(teamId) {
            const team = this.localTeams[teamId];
            if (team === undefined) {
                console.error('removeSchedule: team not found');
                return;
            }
            team.start_time = null;
            team.schedule = [];
        },
        goToEditMode() {
            this.editMode = true;
            this.localTeams = cloneDeep(this.teams);
            this.history = [];
            this.future = [];
        },
        showTeamSelector(slot) {
            this.editStartDate = slot;
        },
        async saveSchedule() {
            for (const teamId of this.teamIds) {
                const team = this.localTeams[teamId];
                if (team === undefined) {
                    console.error('saveSchedule: team not found');
                    continue;
                }
                const schedule = team.schedule;
                const duplicates = schedule.filter((item, index) => schedule.indexOf(item) !== index);
                console.log(duplicates);
                if (duplicates.length > 0) {
                    this.toast.error('Duplicate ' + duplicates[0] + ' in schedule for team ' + team.name);
                    continue;
                }
                await this.$store.direct.dispatch.teams.update({
                    start_time: team.start_time,
                    id: teamId,
                    schedule: team.schedule,
                    room_preferences: team.room_preferences,
                    duration: team.duration
                });
            }
            this.editMode = false;
            this.toast.success('Schedule saved');
        },
        async cancelEdit() {
            this.editMode = false;
            this.localTeams = cloneDeep(this.teams);
            this.history = [];
            this.future = [];
        },
        async cancelSchedule() {
            this.showNewScheduleModal = false;
            this.editableTeams.forEach(team => {
                this.localTeams[team._id].start_time = null;
            });
            this.editableTeams = [];
        },
        async undo() {
            if (this.history.length === 0) {
                return;
            }
            this.future.push(cloneDeep(this.localTeams));
            this.localTeams = this.history.pop();
        },
        async redo() {
            if (this.future.length === 0) {
                return;
            }
            this.history.push(cloneDeep(this.localTeams));
            this.localTeams = this.future.pop();
        },
        // eslint-disable-next-line max-lines-per-function
        async checkSchedule(teams) {
            teams.forEach(team => {
                this.localTeams[team._id].room_preferences = team.room_preferences;
            });
            const scheduleUpdate = await this.$store.direct.dispatch.schedule.getSchedule({
                teams: Object.values(this.localTeams),
                fixed_schedule: this.fixSchedule,
                settings: this.scheduleSettings
            });
            const isValid = scheduleUpdate.changed_team_ids.find(id => teams.map(team => team._id).includes(id));
            console.log('validate', scheduleUpdate.changed_team_ids, isValid);
            if (isValid) {
                this.history.push(cloneDeep(this.localTeams));
                this.future = [];
                Object.values(this.localTeams)
                    .filter(team => scheduleUpdate.changed_team_ids.includes(team._id))
                    .forEach(team => { var _a, _b; return team.schedule = (_b = (_a = scheduleUpdate.teams.find(t => t._id === team._id)) === null || _a === void 0 ? void 0 : _a.schedule) !== null && _b !== void 0 ? _b : []; });
                this.showNewScheduleModal = false;
                this.toast.success('Schedule is valid');
            }
            else {
                this.toast.error('Schedule is invalid, click again to confirm changing others teams');
                this.fixSchedule = false;
            }
        }
    }
});
