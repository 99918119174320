const convertTimestamp = (timestamp, printYear = true, printTime = true) => {
    const date = new Date(timestamp);
    let dateStr = date.getDate() + '/' + (date.getMonth() + 1);
    if (printYear)
        dateStr += '/' + date.getFullYear();
    if (printTime)
        dateStr += ' ' + dateToTimeOfDay(date);
    return dateStr;
};
const midnightOfDate = (date) => {
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
};
const nextAvailableDate = (from, dayOfWeek, hour, minute, reverse) => {
    let nextDate = from;
    const sign = reverse ? -1 : 1;
    while (nextDate.getMinutes() !== minute) {
        nextDate = new Date(nextDate.getTime() + sign * 60 * 1000);
    }
    while (nextDate.getHours() !== hour) {
        nextDate = new Date(nextDate.getTime() + sign * 3600 * 1000);
    }
    while (nextDate.getDay() !== dayOfWeek) {
        nextDate = new Date(nextDate.getTime() + sign * DAY_MILLISECONDS);
    }
    return nextDate;
};
const dateToTimeOfDay = (date, seconds = false) => {
    const hours = `${date.getHours()}`.padStart(2, '0');
    const minutes = `${date.getMinutes()}`.padStart(2, '0');
    const hourMinutes = `${hours}:${minutes}`;
    return seconds ? hourMinutes + ':00' : hourMinutes;
};
const countDaysBetween = (dateFrom, dateTo) => {
    const datespan = midnightOfDate(dateTo).getTime()
        - midnightOfDate(dateFrom).getTime();
    return Math.max(Math.round(datespan / (1000 * 3600 * 24)), 0);
};
const isSameDay = (date1, date2) => {
    if (date1.getFullYear() !== date2.getFullYear())
        return false;
    if (date1.getMonth() !== date2.getMonth())
        return false;
    if (date1.getDate() !== date2.getDate())
        return false;
    return true;
};
const DAY_MILLISECONDS = 24 * 3600 * 1000;
export { convertTimestamp, midnightOfDate, nextAvailableDate, dateToTimeOfDay, countDaysBetween, isSameDay, DAY_MILLISECONDS };
