import { defineComponent, ref, watch } from "vue";
import { ModalBox, Selector } from '@/components/UI';
import './NewScheduleModal.scss';
import useRooms from "@/compositions/useRooms";
import { SchedulePriority } from "@/types/rooms";
import { cloneDeep } from "lodash";
export default defineComponent({
    name: "NewScheduleModal",
    components: {
        ModalBox,
        Selector
    },
    props: {
        modelValue: {
            type: Object,
            required: true
        }
    },
    emits: {
        close() { return true; },
        submit(_value) { return true; },
        'update:modelValue'(_value) { return true; },
        onSettingsChanged(_value) { return true; }
    },
    setup(props, emits) {
        const currentTeams = ref(cloneDeep(props.modelValue));
        const scheduleSettings = ref({
            minimize_chosen_rooms: true,
            handle_combined_rooms: true
        });
        watch(scheduleSettings, () => {
            emits.emit('onSettingsChanged', scheduleSettings.value);
        }, { deep: true });
        const { rooms } = useRooms();
        currentTeams.value.forEach(team => {
            if (team.room_preferences === undefined) {
                team.room_preferences = {};
            }
            Object.keys(rooms.value).forEach(roomId => {
                if (team.room_preferences[roomId] === undefined) {
                    team.room_preferences[roomId] = SchedulePriority.Normal;
                }
            });
        });
        return {
            scheduleSettings,
            currentTeams,
            rooms
        };
    },
    computed: {
        options() {
            return [
                {
                    text: 'Important',
                    value: SchedulePriority.Important
                },
                {
                    text: 'Normal',
                    value: SchedulePriority.Normal
                },
                {
                    text: 'Minor',
                    value: SchedulePriority.Minor
                },
                {
                    text: "Don't schedule",
                    value: SchedulePriority.Never
                }
            ];
        }
    },
    methods: {
        onClose() {
            this.$emit('close');
        },
        onSubmit() {
            this.$emit('onSettingsChanged', this.scheduleSettings);
            this.$emit('update:modelValue', this.currentTeams);
            this.$emit('submit', this.currentTeams);
        }
    }
});
