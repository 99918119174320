import { defineComponent } from "vue";
import { useActiveTeams, useWaitingTeams } from '@/compositions/useTeams';
import GenericSchedule from '@/components/Schedule/GenericSchedule';
import DatePicker from 'vue3-datepicker';
import TeamNewButton from '@/components/Teams/TeamNewButton';
import { Card } from '@/components/UI';
export default defineComponent({
    name: 'ScheduleTab',
    components: {
        GenericSchedule,
        DatePicker,
        TeamNewButton,
        Card
    },
    setup() {
        return {
            ...useActiveTeams(),
            ...useWaitingTeams(),
        };
    },
    data: () => ({
        currentDay: new Date()
    }),
    computed: {
        teams() {
            return { ...this.activeTeams, ...this.waitingTeams };
        },
        teamsReceived() {
            return this.activeTeamsDataReceived && this.waitingTeamsDataReceived;
        }
    }
});
